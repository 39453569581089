import type { RouteLocationRaw } from 'vue-router'
import type { IconName } from '@st/ui/components/StIcon/types'
import { useCasinoDeps } from '@st/casino/useDeps'
import { useFavoriteGamesStore } from '../../stores/useFavoriteGamesStore'

export function useCasinoMenu() {
  const casinoStore = useCasinoStore()
  const { t, locale } = useI18n()
  const { categories } = storeToRefs(casinoStore)
  const { isAuthenticated } = useCasinoDeps()
  const { gameIdsCount } = storeToRefs(useFavoriteGamesStore())
  const stFetch = useRawStFetch()

  const topCategories = computed(() =>
    categories.value.filter(
      (category) => category.type === 'main' && category.visibleForLeftMenu,
    ),
  )

  const bottomCategories = computed(() =>
    categories.value
      .filter(
        (category) => category.type === 'other' && category.visibleForLeftMenu,
      )
      .map((category) => ({
        ...category,
        link: `/casino/categories/${category.parentCode}/${category.code}`,
      })),
  )

  async function fetchCategoryCounter({
    category,
    forMobile,
  }: {
    category: string
    forMobile?: boolean
  }) {
    const { data } = await stFetch('/casino/game/find', {
      method: 'post',
      body: {
        params: {
          categoryCode: category,
          forMobile,
        },
        pagination: {
          page: 0,
          perPage: 20,
          orderBy: [
            {
              fieldName: 'gameId',
              sortOrder: 'ASC',
            },
          ],
        },
      },
    })

    return data.paging.total
  }

  const { data: providers } = useStFetch('/casino/provider/find', {
    method: 'post',
    body: {
      params: {
        forMobile: true,
        language: locale.value as any,
      },
      pagination: {
        page: 0,
        perPage: 50,
        orderBy: [
          {
            fieldName: 'weight',
            sortOrder: 'DESC',
          },
        ],
      },
    },
  })

  const providersCounter = computed(() => providers.value?.paging.total)

  const myGames = computed(() => ({
    code: 'favorites',
    name: t('casino.myGames'),
    iconName: 'star-solid' as IconName,
    link: '/casino/recent' as RouteLocationRaw,
    counter: gameIdsCount.value,
    couterCustomColor: 'white',
    couterCustomBgColor: 'ghost',
  }))

  const mobileCountersMap: Ref<Record<string, number>> = ref({})

  topCategories.value.forEach(async (item) => {
    const total = await fetchCategoryCounter({
      category: item.code,
      forMobile: true,
    })
    mobileCountersMap.value[item.code] = total
  })

  const extendedTopCategories = computed<any>(() =>
    isAuthenticated.value
      ? [myGames.value, ...topCategories.value]
      : topCategories.value,
  )

  return {
    extendedTopCategories,
    bottomCategories,
    fetchCategoryCounter,
    mobileCounters: mobileCountersMap,
    providersCounter,
  }
}
