<template>
  <div class="casino-menu" data-t="casino-menu">
    <StMobileMenuItem
      v-for="category in extendedTopCategories"
      :key="category.code"
      :title="category.name"
      :icon="category.iconName"
      :icon-link="category.iconLink"
      :link="category.link || `/casino/categories/${category.code}`"
      :counter="getCounter(category.code)"
      :live="false"
      :data-t="`menu-item-${category.code}`"
    />
    <StMobileMenuItem
      :title="t('menu.providers')"
      icon="providers"
      :level="2"
      :counter="providersCounter"
      :live="false"
      link="/casino/providers"
      data-t="menu-item-providers"
    />
    <div class="section-title">{{ t('menu.functions') }}</div>
    <div class="functions-wrapper" data-t="functions-6tov">
      <BottomMenuItem
        v-for="(category, idx) in bottomCategories"
        :key="category.code"
        :code="category.name || category.code"
        :icon-link="category.iconLink"
        :icon-color="getIconColor(idx)"
        :link="category.link"
        :data-t="`menu-item-${category.code}`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import StMobileMenuItem from '@st/ui/components/StMobileMenuItem/StMobileMenuItem.vue'
import BottomMenuItem from './parts/BottomMenuItem.vue'
import { useCasinoMenu } from './useCasinoMenu'

const { t } = useI18n()
const {
  extendedTopCategories,
  bottomCategories,
  mobileCounters,
  providersCounter,
} = useCasinoMenu()

const getCounter = computed(
  () => (category: string) => mobileCounters.value[category],
)

const colors = [
  '#F4BF2B',
  '#38C9AF',
  '#A664E8',
  '#EC5F60',
  '#4DB351',
  '#F165B7',
  '#A493F5',
  '#2CA5E9',
]

function getIconColor(idx: number) {
  const colorIdx = idx % colors.length
  return colors[colorIdx]
}
</script>

<style scoped>
.casino-menu {
  margin-bottom: var(--spacing-400);
}

.section-title {
  padding: var(--spacing-300) var(--spacing-200) 0;
  font: var(--mobile-title-2-semibold);
  color: var(--text-primary);
}

.functions-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-125);

  margin-bottom: var(--spacing-400);
  padding: var(--spacing-150) var(--spacing-200);
}
</style>
